
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import { Vue, Component, Prop } from 'vue-property-decorator';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';
import { Inject } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: {
        'el-dropdown': Dropdown,
        'el-dropdown-menu': DropdownMenu,
        'el-dropdown-item': DropdownItem,
    },
})
export default class CarClass extends Vue {
    @Inject(CarsCategoryManagerServiceS) private carsCategoryManagerService!: CarsCategoryManagerService;
    @Inject(UserServiceS) private userService!: UserService;

    @Prop({ required: false })
    private carClass!: string;

    @Prop({ required: true })
    private dataSource!: string;

    @Prop({ required: true })
    private availableDataSources!: string[];

    get isReadOnly() {
        if (!this.userService.isAdmin) {
            return true;
        }
        return this.userService.isReadonly;
    }

    handleClassSelection(category: string) {
        this.$emit('changeClass', { className: this.carClass, newCategory: category, dataSource: this.dataSource });
    }

    get dataSources() {
        const all = this.carsCategoryManagerService.dataSources;
        return all ? all.filter(src => src !== this.dataSource) : null;
    }
}

