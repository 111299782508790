
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import draggable, { MoveEvent, EndEvent } from 'vuedraggable';
import CarClass from '@/modules/cars-category-manager/components/car-class.vue';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: {
        draggable,
        CarClass,
    },
})
export default class CategoryDraggable extends Vue {
    @Inject(CarsCategoryManagerServiceS) private carsCategoryManagerService!: CarsCategoryManagerService;
    @Inject(UserServiceS) private userService!: UserService;

    @Prop({ required: true, type: Object })
    private dataSourceClasses!: any;

    @Prop({ required: true, type: String })
    private category!: string;

    @Prop({ required: true, type: Array })
    private categoryDataSources!: Array<string[]>;

    get dataSources() {
        return this.carsCategoryManagerService.dataSources;
    }

    get isReadOnly() {
        if (!this.userService.isAdmin) {
            return true;
        }
        return this.userService.isReadonly;
    }

    getCarClasses(dataSource: string) {
        const list: string[] = [];
        if (this.dataSourceClasses[dataSource]) {
            this.dataSourceClasses[dataSource].forEach((item: string) => {
                list.push(item);
            });
        }
        return list;
    }

    isDraggable(event: MoveEvent<any>) {
        return event.from !== event.to;
    }

    handleMove(event: EndEvent, category: string) {
        if (event.from.id !== event.to.id) {
            this.carsCategoryManagerService.addLocalChanges(category, event.item.id, event.from.id, event.to.id);
        }
        this.carsCategoryManagerService.storeState.dragChoose = '';
    }

    changeClass(event: { className: string, newCategory: string, dataSource: string }) {
        this.carsCategoryManagerService.addLocalChanges(this.category, event.className, event.dataSource, event.newCategory);
    }

    handleChoose(event: any, dataSource: string) {
        this.carsCategoryManagerService.storeState.dragChoose = dataSource;
    }
}
